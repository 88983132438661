var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showRevokeButton
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: _vm.revokeLoading, "max-width": "500" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on: dialog }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on: tooltip }) {
                                    return [
                                      _vm.hierarchicalLevel ===
                                        _vm.nuvolosObjectTypes.ORGANIZATION ||
                                      _vm.hierarchicalLevel ===
                                        _vm.nuvolosObjectTypes.SPACE ||
                                      (_vm.reason &&
                                        _vm.hierarchicalLevel ===
                                          _vm.nuvolosObjectTypes.INSTANCE &&
                                        _vm.reason.toUpperCase() ===
                                          "EXPLICIT") ||
                                      (_vm.hierarchicalLevel ===
                                        _vm.nuvolosObjectTypes.INSTANCE &&
                                        !_vm.spaceAdmin)
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  small: "",
                                                  color: "secondary",
                                                  icon: "",
                                                },
                                              },
                                              { ...tooltip, ...dialog }
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("clear")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.hierarchicalLevel ===
                                            _vm.nuvolosObjectTypes.INSTANCE &&
                                          _vm.reason.toUpperCase() !==
                                            "EXPLICIT"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { left: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [_vm._v("clear")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " You cannot revoke " +
                                                    _vm._s(
                                                      _vm.reason ===
                                                        _vm.roleTypes
                                                          .SPACE_ADMIN
                                                        ? " a space admin"
                                                        : _vm.reason ===
                                                          _vm.roleTypes
                                                            .ORG_MANAGER
                                                        ? " an organization manager"
                                                        : _vm.reason + " role."
                                                    ) +
                                                    ". "
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Revoke User Access")])]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                12355343
              ),
              model: {
                value: _vm.revokeDialog,
                callback: function ($$v) {
                  _vm.revokeDialog = $$v
                },
                expression: "revokeDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title secondary--text text-uppercase d-flex align-center",
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("clear"),
                        ]),
                        _vm._v(
                          " revoke from " + _vm._s(_vm.hierarchicalLevel) + " "
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("v-divider", { staticClass: "mb-1" }),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column pa-2 mt-2 mb-2 info-tile",
                        },
                        [
                          _c("span", [
                            _vm._v(" User: "),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm.userName)),
                            ]),
                          ]),
                          _vm.hierarchicalLevel ===
                          _vm.nuvolosObjectTypes.INSTANCE
                            ? _c("span", [
                                _vm._v(" Instance: "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.instanceName))]
                                ),
                              ])
                            : _vm.hierarchicalLevel ===
                              _vm.nuvolosObjectTypes.SPACE
                            ? _c("span", [
                                _vm._v(" Space: "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.spaceName))]
                                ),
                              ])
                            : _vm.hierarchicalLevel ===
                              _vm.nuvolosObjectTypes.ORGANIZATION
                            ? _c("span", [
                                _vm._v(" Organization: "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.orgName))]
                                ),
                              ])
                            : _vm._e(),
                          _c("span", [
                            _vm._v(" Role: "),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm.role)),
                            ]),
                          ]),
                        ]
                      ),
                      _vm.hierarchicalLevel === _vm.nuvolosObjectTypes.INSTANCE
                        ? _c("span", [
                            _vm._v(
                              " By revoking the user instance role, the user will no longer be able to see or work with the instance. "
                            ),
                          ])
                        : _vm._e(),
                      _vm.hierarchicalLevel === _vm.nuvolosObjectTypes.SPACE
                        ? _c("span", [
                            _vm._v(
                              " By revoking the user space role, the user will lose the administrator rights for this space but will keep the instance-level roles if any. "
                            ),
                          ])
                        : _vm._e(),
                      _vm.hierarchicalLevel ===
                      _vm.nuvolosObjectTypes.ORGANIZATION
                        ? _c("span", [
                            _vm._v(
                              " By revoking the user organization role, the user will no longer be able to see or work with any of the spaces and instances in the organization. "
                            ),
                          ])
                        : _vm._e(),
                      _vm.hierarchicalLevel === _vm.nuvolosObjectTypes.SPACE
                        ? _c(
                            "v-checkbox",
                            {
                              attrs: {
                                label:
                                  "Revoke all instance roles for this user.",
                              },
                              model: {
                                value: _vm.revokeSpaceChildren,
                                callback: function ($$v) {
                                  _vm.revokeSpaceChildren = $$v
                                },
                                expression: "revokeSpaceChildren",
                              },
                            },
                            [_vm._v(" Revoke ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            disabled: _vm.revokeLoading,
                            color: "secondary",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.revokeDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.revokeLoading,
                            text: "",
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmAction("revokeAccess", _vm.ucid)
                            },
                          },
                        },
                        [_vm._v("Revoke")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.userInfo.is_sysadmin &&
      _vm.isActiveUser &&
      _vm.showDeactivateActivateButton
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: _vm.activationLoading, "max-width": "500" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on: dialog }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on: tooltip }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              small: "",
                                              color: "error",
                                              text: "",
                                              icon: "",
                                            },
                                          },
                                          { ...tooltip, ...dialog }
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-account-off")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Deactivate User")])]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1161924684
              ),
              model: {
                value: _vm.deactivateDialog,
                callback: function ($$v) {
                  _vm.deactivateDialog = $$v
                },
                expression: "deactivateDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title secondary--text text-uppercase d-flex align-center",
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("mdi-account-off"),
                        ]),
                        _vm._v(" user deactivation "),
                      ],
                      1
                    ),
                  ]),
                  _c("v-divider", { staticClass: "mb-1" }),
                  _c("v-card-text", [
                    _vm._v(
                      " Are you sure you want to deactivate the account of the user "
                    ),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.userName)),
                    ]),
                    _vm._v(" ? "),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            disabled: _vm.activationLoading,
                            color: "secondary",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.deactivateDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.activationLoading,
                            text: "",
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmAction(
                                "deactivateUser",
                                _vm.uid
                              )
                            },
                          },
                        },
                        [_vm._v("deactivate")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.userInfo.is_sysadmin &&
      !_vm.isActiveUser &&
      _vm.showDeactivateActivateButton
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: _vm.activationLoading, "max-width": "500" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on: dialog }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on: tooltip }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              small: "",
                                              color: "success",
                                              text: "",
                                              icon: "",
                                            },
                                          },
                                          { ...tooltip, ...dialog }
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-account-check")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Activate User")])]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3522065599
              ),
              model: {
                value: _vm.activateDialog,
                callback: function ($$v) {
                  _vm.activateDialog = $$v
                },
                expression: "activateDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title secondary--text text-uppercase d-flex align-center",
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("mdi-account-check"),
                        ]),
                        _vm._v(" user activation "),
                      ],
                      1
                    ),
                  ]),
                  _c("v-divider", { staticClass: "mb-1" }),
                  _c("v-card-text", [
                    _vm._v(
                      " Are you sure you want to activate the account of the user "
                    ),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.userName)),
                    ]),
                    _vm._v(" ? "),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            disabled: _vm.activationLoading,
                            color: "secondary",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.activateDialog = false
                            },
                          },
                        },
                        [_vm._v("cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.activationLoading,
                            text: "",
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmAction("activateUser", _vm.uid)
                            },
                          },
                        },
                        [_vm._v("activate")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }